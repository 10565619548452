import { HiOutlineArrowSmallDown } from 'react-icons/hi2';
import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useRouter } from 'next/router';
import Image from 'next/image';
import { Pagination } from 'swiper/modules';
import { SwiperNavButtons } from '../../components/SwiperNavButtons';
import { SVG } from '../../components/SVG';
import Head from 'next/head';
import Link from 'next/link';
import { useVisibleContext } from '../../components/VisibleContext';
import { base_url } from '../../utils/base_url';
import { token } from '../../utils/axiosconfig';
import axios from 'axios';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import Service from '../../components/Service';
import { LoadingOverlay } from '../../components/LoadingOverlay';
import { useTranslation } from '../../components/TranslationContext';
import Popup from 'reactjs-popup';

export async function getServerSideProps(context) {
  try {
    context.res.setHeader(
      'Cache-Control',
      'public, s-maxage=10, stale-while-revalidate=59'
    );
    const { query } = context;

    const lang = query.lang || 'az';
    const config = {
      headers: {
        'Accept-Language': lang,
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    };

    const request = [
      axios.get(`${base_url}/api/popups`, config),
      axios.get(`${base_url}/api/tariffs`, config),
      axios.get(`${base_url}/api/reviews`, config),
      axios.get(`${base_url}/api/colors`, config),
      axios.get(`${base_url}/api/advantages`, config),
      axios.get(`${base_url}/api/posts?published`, config),
      axios.get(`${base_url}/api/regions`, config),
      axios.get(`${base_url}/api/settings`, config),
      axios.get(`${base_url}/api/service-categories`, config),
      axios.get(`${base_url}/api/slides`, config),
      axios.get(`${base_url}/api/services`, config),
    ];

    const [
      Popupresponse,
      Tariffresponse,
      Reviewresponse,
      Colorresponse,
      Advantageresponse,
      Newsresponse,
      Regionresponse,
      Settingresponse,
      ServiceCategoryresponse,
      Slideresponse,
      Serviceresponse,
    ] = await Promise.all(request);

    return {
      props: {
        ReviewData: Reviewresponse.data,
        NewsData: Newsresponse.data,
        RegionData: Regionresponse.data,
        AdvantageData: Advantageresponse.data,
        ColorData: Colorresponse.data,
        SettingData: Settingresponse.data,
        ServiceCategoryData: ServiceCategoryresponse.data,
        SlideData: Slideresponse.data,
        PopupData: Popupresponse.data,
        ServiceData: Serviceresponse.data,
      },
    };
  } catch (error) {
    console.error(error);
    return {
      props: {
        error: 'Error',
      },
    };
  }
}

const home = ({
  ReviewData,
  NewsData,
  RegionData,
  AdvantageData,
  ColorData,
  SettingData,
  ServiceCategoryData,
  SlideData,
  ServiceData,
}) => {
  const filteredData = ServiceData?.data?.filter(
    (item) => item.fiber_optic === true
  );
  const allTariffsArrays = filteredData?.map((item) => item.tariffs) || [];
  const flattenedTariffs = allTariffsArrays?.flat();

  const type1Tariffs = flattenedTariffs?.filter((tariff) => tariff.type === 1);
  const type2Tariffs = flattenedTariffs?.filter((tariff) => tariff.type === 2);
  const { translate, Language, currentLanguage } = useTranslation();

  const [isLoading, setIsLoading] = useState(true);

  const [selectedValue, setSelectedValue] = useState('');
  const [svgValue, setSvgValue] = useState('');

  const { isOpen, toggleMenu } = useVisibleContext();

  const router = useRouter();

  const { visible, setVisible } = useVisibleContext();

  const handleScrollUp = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  const handleScrollDown = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    const { pathname } = router;

    if (pathname === '/' && !router.query.lang) {
      router.push('/az', undefined, { shallow: true });
    }
  }, []);

  useEffect(() => {
    setVisible(router.pathname === '/a');
  }, [router.pathname]);

  const handleClick = (slug) => {
    router.push(`${currentLanguage}/news/${slug}`);
  };

  const pageTitle = SettingData?.data
    .filter((item) => item.key === 'home_page_meta_title')
    ?.map((item) => item.value);
  const pageDescription = SettingData?.data
    .filter((item) => item.key === 'home_page_meta_description')
    .map((item) => item.value);
  const career_title = SettingData?.data
    .filter((item) => item.key === 'home_page_career_title')
    ?.map((item) => item.value);

  const career_description = SettingData?.data
    .filter((item) => item.key === 'home_page_career_description')
    ?.map((item) => item.value);

  const home_page_other_tariffs_button_link = SettingData?.data
    .filter((item) => item.key === 'home_page_other_tariffs_button_link')
    ?.map((item) => item.value);

  const map_title = SettingData?.data
    .filter((item) => item.key === 'home_page_map_title')
    ?.map((item) => item.value);

  const map_description = SettingData?.data
    .filter((item) => item.key === 'home_page_map_description')
    ?.map((item) => item.value);

  const advantage_down_button_link = SettingData?.data
    .filter((item) => item.key === 'advantage_down_button_link')
    ?.map((item) => item.value);

  useEffect(() => {
    handleButtonClick();
  }, [selectedValue]);

  const handleButtonClick = () => {
    if (selectedValue) {
      setSvgValue(selectedValue);
    } else {
      setSvgValue(null);
    }
  };
  const [selectedItem, setSelectedItem] = useState('ferdi');

  const handleItemClick = (item, position) => {
    if (selectedItem !== item) {
      setSelectedItem(item);
    }
  };
  useEffect(() => {
    if (typeof window === 'undefined') {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, []);
  const [issOpen, setIsOpen] = useState(false);

  return (
    <>
      <Head>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
      </Head>
      {visible ? <Service ServiceCategoryData={ServiceCategoryData} /> : null}
      {isLoading ? <LoadingOverlay /> : null}
      <div className="home-wrapper-2 bg-[#F7F6FB] pb-10">
        <div className="container max-w-[1100px] flex justify-center mx-auto pt-10 max-sm:pt-5">
          <Carousel
            showThumbs={false}
            infiniteLoop={true}
            autoPlay={true}
            interval={5000}
            showStatus={false}
            className="home-slide w-full max-w-[1150px]"
            renderArrowPrev={(onClickHandler, hasPrev, label) =>
              hasPrev && (
                <button onClick={onClickHandler} title={label} className="slide-prev">
                  <img src="/assets/slide_prev.png" alt=""/>
                </button>
              )
            }
            renderArrowNext={(onClickHandler, hasNext, label) =>
              hasNext && (
                <button onClick={onClickHandler} title={label} className="slide-next">
                  <img src="/assets/slide_next.png" alt=""/>
                </button>
              )
            }
          >
            {SlideData?.data?.map((slide) => (
              <div
                key={slide.id}
                className="relative flex justify-between max-w-[1150px]"
              >
                <Image
                  src={slide.image}
                  width={0}
                  height={0}
                  sizes="100vw"
                  className="rounded-xl"
                  alt=""
                />
                <div className="absolute top-0 left-0 right-0 bottom-0 container flex justify-start flex-col mx-20 max-sm:mx-10 mt-5">
                  {' '}
                  <p className="text-[40px] flex justify-start  text-white font-normal max-sm:text-[13px] max-md:text-[20px] max-xl:text-[30px]">
                    {slide.title}
                  </p>
                  <p className="text-[40px] flex justify-start text-white font-bold max-sm:text-[13px] max-md:text-[20px] max-xl:text-[30px]">
                    {slide.description}
                  </p>
                  <Link
                    href={slide.button_link ? slide.button_link : '#/'}
                    className="border p-2 rounded-3xl text-white text-[15px] w-[170px] h-[40px] max-sm:w-[120px] max-sm:h-[32px] max-sm:text-[11px] text-center mt-2 max-sm:mt-2 flex justify-center items-center overflow-hidden"
                  >
                    {slide.button_text}
                  </Link>
                </div>
              </div>
            ))}
          </Carousel>
        </div>
        <div className="absolute left-[48%] w-16 h-16 max-sm:hidden rounded-full flex justify-center items-center">
          <button className="" onClick={handleScrollDown}>
            <Image
              src="/assets/arrow_down.png"
              height="50"
              width="50"
              alt=""
            />
          </button>
        </div>
        <div className="container max-w-[1087px] mx-auto mt-20 max-sm:mt-5 ">
          <div className="flex flex-col justify-center items-center gap-3">
            <h3 className="text-[40px] text-[#5B2D90] font-bold tracking-[0.5px] max-sm:text-[20px] max-xxl:text-[30px]">
              {map_title}
            </h3>
            <p className="text-center text-[#94A2B3] w-3/4 max-sm:text-[10px] max-sm:w-[307px] max-sm:mx-auto ">
              {map_description}
            </p>
          </div>
          <div className="grid grid-cols-3 max-xl:mx-10  mt-14 max-sm:mt-5 max-llg:justify-items-center">
            <div className="max-llg:col-span-3 max-llg:flex max-llg:flex-col max-llg:justify-center max-llg:items-center">
              <h3 className="text-[40px] text-[#5B2D90] font-bold tracking-[0.5px] max-sm:text-[20px] max-sm:text-center">
                {translate('Select_the_region', Language)}
              </h3>
              <div className="border border-[#637381] rounded-3xl w-3/4 max-sm:w-[204px] max-sm:h-[40px] overflow-hidden max-sm:mt-3">
                <select
                  className="text-[#637381] bg-inherit border-none text-[15px] font-medium rounded-lg block w-full p-2.5 focus:ring-0 hom"
                  value={selectedValue}
                  id=""
                  onChange={(e) => setSelectedValue(e.target.value)}
                >
                  <option value=""> {translate('Select', Language)}</option>
                  {RegionData?.data?.map((region) => (
                    <option key={region.id} value={region.handle}>
                      {region.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>{' '}
            <div className="col-span-2 flex justify-end items-center max-llg:hidden">
              <div className="col-span-2 h-96 w-3/4 max-[1100px]:w-[500px] rounded-3xl overflow-hidden">
                <div className="absolute svg">
                  <SVG region={svgValue} RegionData={RegionData} />
                </div>
              </div>
            </div>
            <div className="col-span-3 pt-20 max-llg:hidden">
              <ul className="flex justify-center gap-20 text-[16px] font-medium">
                {ColorData?.data?.map((item, index) => {
                  return (
                    <li className="flex gap-2 items-center" key={index}>
                      <div
                        className={`w-9 h-9 rounded-full `}
                        style={{ backgroundColor: item.code }}
                      />
                      {item.name}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${
          isOpen ? 'z-[-1]' : 'z-0'
        } home-wrapper-4 w-full  pt-20 mx-auto relative `}
        id="ustunluklerimiz"
      >
        <div className="container relative max-w-[1087px] mx-auto">
          <h3
            className={`${
              isOpen ? 'z-[-1]' : 'z-0'
            } text-[40px] text-[#5B2D90] font-bold text-center max-sm:text-[20px] max-xxl:text-[30px]`}
          >
            {translate('Our_advantages', Language)}
          </h3>
          <div className="grid grid-cols-3 justify-items-center gap-5 mt-10 max-lg:grid-cols-1 max-xxl:grid-cols-2">
            {AdvantageData?.data?.slice(0, 6).map((item) => (
              <div key={item.id}>
                <div className="w-[344px] h-[204px] max-sm:h-[124px] max-sm:w-[308px] max-xxl:h-[174px] max-xxl:w-[445px]  flex flex-col justify-center items-center border border-[#CDCDCD] p-7  gap-5 rounded-2xl max-xl:flex-row max-xl:justify-strech   max-xl:items-center  max-xxl:flex-col max-xxl:justify-strech   max-xxl:items-start"> 
                  <div className="flex flex-col max-xl:flex-row justify-between items-start max-xl:justify-space max-xl:items-center max-xxl:justify-center max-xxl:items-center  gap-3   w-full">
                    <div style={{ backgroundColor: item.backgroundColor }} className="flex justify-center items-center w-[65px] h-[65px] rounded-xl ">
                      <Image
                        src={item.icon}
                        width={40}
                        height={40}
                        alt=""
                      />
                    </div>
                    <p className="text-[24px] font-semibold max-md:text-[15px] max-xxl:text-[16px]">
                      {item.title}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="flex justify-center">
            <button className="w-[244px] h-[60px] bg-[#5B2D90] rounded-full text-white text-[18px] mt-10">
              {advantage_down_button_link && advantage_down_button_link[0] !== null ? (
                <a
                  href={
                    advantage_down_button_link
                      ? `${advantage_down_button_link}`
                      : '#/'
                  }
                >
                  {translate('Join_us', Language)}
                </a>
              ) : (
                <p> {translate('Join_us', Language)}</p>
              )}
            </button>
          </div>
          <Image
            src="/assets/wifi.png"
            height={300}
            width={300}
            className="wifi max-md:w-[130px] max-xl:w-[200px] w-[300px]"
            alt=""
          />
        </div>
      </div>
      <div className="home-wrapper-5 w-full pb-20 relative pt-5 max-lg:py-0 ">
        <div className="">
          <Image
            src="/assets/home3.png"
            width={500}
            height={300}
            className="absolute w-full h-[900px]  max-xl:hidden  object-fill"
            alt=""
          />
        </div>
        <Image
          src="/assets/home4.png"
          width={500}
          height={300}
          className=" absolute w-full h-[650px] object-cover hidden max-xl:block  "
          alt=""
        />
        <div className="container max-w-[1100px] mx-auto pt-12 ">
          <h3
            className="text-[40px] text-white relative  mt-20 max-xl:mt-10 font-bold text-center max-lg:text-[20px]  max-xxl:text-[25px] max-sm:mt-10 "
            id="fiber"
          >
            {translate('Fiber_optic', Language)}{' '}
          </h3>
          <div className="relative text-[20px] max-lg:text-[16px] max-xl:text-[18px] font-medium text-[#94A2B3] flex gap-5 justify-center items-center mt-3 overflow-hidden">
            {type1Tariffs?.length !== 0 ? (
              <button
                onClick={() => {
                  handleItemClick('ferdi');
                }}
                className={`border w-[192px] h-[59px] max-xl:w-[140px] max-xl:h-[36px]  rounded-3xl ${
                  selectedItem === 'ferdi'
                    ? 'border-white' && 'text-white'
                    : 'border-[#94A2B3] '
                }  overflow-hidden flex justify-center items-center`}
              >
                {translate('Individual', Language)}
              </button>
            ) : (
              ''
            )}
            {type2Tariffs?.length !== 0 ? (
              <button
                onClick={() => {
                  handleItemClick('biznes');
                }}
                className={`border w-[192px] h-[59px]  max-xl:w-[140px] max-xl:h-[36px] ${
                  selectedItem === 'biznes'
                    ? 'border-white' && 'text-white'
                    : 'border-[#94A2B3] '
                }  px-12 py-3 rounded-3xl  overflow-hidden flex justify-center items-center`}
              >
                {translate('Business', Language)}
              </button>
            ) : (
              ''
            )}
          </div>
          <div
            className="flex flex-nowrap max-w-[1099px] justify-center justify-items-center gap-5 mt-10 max-xl:hidden overflow-x-auto"
            id="fiber"
          >
            {selectedItem === 'ferdi' && (
              <>
                {type1Tariffs?.map((item) => {
                  return (
                    <div className="h-[500px] w-[210px]  p-0 op" key={item.id}>
                      <div
                        className={`w-[200px] h-[350px] max-sm:w-[195px] max-sm:h-[332px] rounded-t-[100px]  rounded-b-[20px] bg-gradient-to-b from-[#653E98] via-[transparent] to-[#3E2164] flex flex-col justify-start items-center ${
                          item.ip_tv == true ? 'gap-2' : 'gap-3'
                        }   relative z-10  mt-5  ml-1 ${
                          item.most_wanted === true ? 'outline-red' : ''
                        }`}
                      >
                        <div className="flex justify-center items-center w-[65px] h-[65px] bg-[#AB31D6] rounded-full mt-3">
                          <Image
                            src={item.icon}
                            width={26}
                            height={29}
                            alt=""
                          />
                        </div>
                        <p className="text-[20px] font-bold text-white">
                          {item.name}
                        </p>
                        <div className="border-[1px] w-32 border-[#f1f1f1] opacity-20" />
                        <p className="text-white text-[24px] font-bold">
                          {item.speed} Mb/s
                        </p>
                        <div
                          className={`${
                            item?.ip_tv === true ? 'block' : 'hidden'
                          } bg-[#FFA35B] w-full h-[28px]  flex  justify-center items-center gap-2  `}
                        >
                          <Image
                            src="/assets/packets/tv2.png"
                            width={11}
                            height={15}
                            className="w-[11px] h-[15px]"
                            alt=""
                          />
                          <p className="text-[10px] font-bold text-[#5B2D90]">
                            {translate('IP_TV', Language)}
                          </p>
                        </div>
                        <div className="text-white text-[10px] font-bold flex justify-center items-center gap-1">
                          <Image
                            src="/assets/packets/pq2.png"
                            width={11}
                            height={15}
                            className="w-[11px] h-[15px]"
                            alt=""
                          />
                          <span>
                            {translate('Fee', Language)}
                          </span>
                        </div>
                        <div className="border-[1px] w-32 border-[#f1f1f1] opacity-20" />
                        <p className="text-[20px] font-bold text-[#FFA35B] flex justify-center items-center gap-1 overflow-hidden">
                          {item.price}
                          <Image
                            src="/assets/packets/azn.png"
                            width={20}
                            height={20}
                            className="h-5"
                            alt=""
                          />
                        </p>
                        <Popup
                          trigger={
                            <p className="w-[100px] h-[30px] text-[8px] flex justify-center items-center font-medium text-white bg-[#AB31D6] rounded-full cursor-pointer">
                              {translate('Detailed_information', Language)}
                            </p>
                          }
                          modal
                          nested
                          contentStyle={{
                            padding: '0px',
                            borderRadius: '20px',
                            borderColor: 'white',
                            width: '40%',
                            height: 'auto',
                            overflow: 'auto',
                          }}
                        >
                          {(close) => (
                            <>
                              <Image
                                src="/assets/popup/x.png"
                                className="absolute right-2 top-2 w-[34px] h-[36px]"
                                alt=""
                                width={40}
                                height={42}
                                onClick={() => {
                                  close();
                                  setIsOpen(false);
                                }}
                              />
                              <div className="flex justify-center items-center p-5 mt-5 break-keep">
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: item.description,
                                  }}
                                ></span>
                              </div>
                              <div className="flex justify-center items-center p-5 mt-5 break-keep">
                                <Link href={`${item.button}`}
                                  className="w-[178px] h-[40px] block bg-[#5B2D90] flex justify-center items-center rounded-3xl text-[16px] text-white font-medium mt-5">
                                    {translate('Register', Language)}
                                </Link>
                              </div>
                            </>
                          )}
                        </Popup>
                      </div>
                      <div className="flex justify-center">
                        <div
                          className={`${
                            item.most_wanted === true ? 'flag-home' : 'hidden'
                          }   mt-0 text-[8px] text-center font-medium justify-center`}
                        >
                          {translate('Preferred', Language)}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            )}
            {selectedItem === 'biznes' && (
              <>
                {type2Tariffs?.map((item) => (
                  <div className="h-[500px] w-[210px]  p-0 op" key={item.id}>
                    <div
                      className={`w-[200px] h-[350px] max-sm:w-[195px] max-sm:h-[332px] rounded-t-[100px]  rounded-b-[20px] bg-gradient-to-b from-[#653E98] via-[transparent] to-[#3E2164] flex flex-col justify-start items-center ${
                        item.ip_tv === true ? 'gap-2' : 'gap-3'
                      }   relative z-10  mt-5  ml-1 ${
                        item.most_wanted === true ? 'outline-red' : ''
                      }`}
                    >
                      <div className="flex justify-center items-center w-[65px] h-[65px] bg-[#AB31D6] rounded-full mt-3">
                        <Image src={item.icon} width={26} height={29} alt="" />
                      </div>
                      <p className="text-[20px] font-bold text-white">
                        {item.name}
                      </p>
                      <div className="border-[1px] w-32 border-[#f1f1f1] opacity-20" />
                      <p className="text-white text-[24px] font-bold">
                        {item.speed} Mb/s
                      </p>
                      <div
                        className={`${
                          item?.ip_tv === true ? 'block' : 'hidden'
                        } bg-[#FFA35B] w-full h-[28px]  flex  justify-center items-center gap-2  `}
                      >
                        <Image
                          src="/assets/packets/tv2.png"
                          width={11}
                          height={15}
                          className="w-[11px] h-[15px]"
                          alt=""
                        />
                        <p className="text-[10px] font-bold text-[#5B2D90]">
                          {translate('IP_TV', Language)}
                        </p>
                      </div>
                      <div className="text-white text-[10px] font-bold flex gap-1">
                        <Image
                          src="/assets/packets/pq2.png"
                          width={11}
                          height={15}
                          className="w-[11px] h-[15px]"
                          alt=""
                        />
                        <span>
                            {translate('Fee', Language)}
                          </span>
                      </div>
                      <div className="border-[1px] w-32 border-[#f1f1f1] opacity-20" />
                      <p className="text-[20px] font-bold text-[#FFA35B] flex justify-center items-center gap-1 overflow-hidden">
                        {item.price}
                        <Image
                          src="/assets/packets/azn.png"
                          width={20}
                          height={20}
                          className="h-5"
                          alt=""
                        />
                      </p>
                      <Popup
                        trigger={
                          <p className="w-[100px] h-[30px] text-[8px] flex justify-center items-center font-medium text-white bg-[#AB31D6] rounded-full cursor-pointer">
                            {translate('Detailed_information', Language)}
                          </p>
                        }
                        modal
                        nested
                        contentStyle={{
                          padding: '0px',
                          borderRadius: '20px',
                          borderColor: 'white',
                          width: '40%',
                          height: 'auto',
                          overflow: 'auto',
                        }}
                      >
                        {(close) => (
                          <>
                            <Image
                              src="/assets/popup/x.png"
                              className="absolute right-2 top-2 w-[34px] h-[36px]"
                              alt=""
                              width={40}
                              height={42}
                              onClick={() => {
                                close();
                                setIsOpen(false);
                              }}
                            />
                            <div className="flex justify-center items-center p-5 mt-5 break-keep">
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: item.description,
                                }}
                              ></span>
                            </div>
                            <div className="flex justify-center items-center p-5 mt-5 break-keep">
                                <Link href={`${item.button}`}
                                  className="w-[178px] h-[40px] block bg-[#5B2D90] flex justify-center items-center rounded-3xl text-[16px] text-white font-medium mt-5">
                                    {translate('Register', Language)}
                                </Link>
                              </div>
                          </>
                        )}
                      </Popup>
                    </div>
                    <div className="flex justify-center">
                      <div
                        className={`${
                          item.most_wanted === true ? 'flag-home' : 'hidden'
                        }   mt-0 text-[8px] text-center font-medium justify-center`}
                      >
                        {translate('Preferred', Language)}
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
          {selectedItem === 'ferdi' && (
            <>
              <div className="swiper">
                <Swiper
                  slidesPerView={2}
                  centeredSlides={true}
                  spaceBetween={170}
                  pagination={{
                    clickable: true,
                  }}
                  modules={[Pagination]}
                  className="mySwiper"
                >
                  {type1Tariffs?.map((item) => (
                    <SwiperSlide key={item.id}>
                      {' '}
                      <div className="h-[410px] w-[210px] flex flex-col justify-center items-center p-0 op">
                        <div
                          className={`w-[200px] h-[350px] max-sm:w-[195px] max-sm:h-[332px] rounded-t-[100px]  rounded-b-[20px] bg-gradient-to-r from-[#653E98] to-[#3E2164] flex flex-col justify-start items-center ${
                            item.ip_tv === true ? 'gap-2' : 'gap-3'
                          }   relative z-10   ${
                            item.most_wanted === true
                              ? 'outline-red'
                              : undefined
                          }`}
                        >
                          <div className="flex justify-center items-center w-[65px] h-[65px] bg-[#AB31D6] rounded-full mt-3">
                            <Image
                              src={item.icon}
                              width={20}
                              height={20}
                              alt=""
                            />
                          </div>
                          <p className="text-[20px] font-bold text-white">
                            {item.name}
                          </p>
                          <div className="border-[1px] w-32 border-[#f1f1f1] opacity-20" />
                          <p className="text-white text-[24px] font-bold">
                            {item.speed} Mb/s
                          </p>
                          <div
                            className={`${
                              item?.ip_tv === true ? 'block' : 'hidden'
                            } bg-[#FFA35B] w-full h-[28px]  flex  justify-center items-center gap-2  `}
                          >
                            <Image
                              src="/assets/packets/tv2.png"
                              width={11}
                              height={15}
                              className="w-[11px] h-[15px]"
                              alt=""
                            />
                            <p className="text-[10px] font-bold text-[#5B2D90]">
                              {translate('IP_TV', Language)}
                            </p>
                          </div>
                          <div className="text-white text-[10px] font-bold flex gap-1">
                            <Image
                              src="/assets/packets/pq2.png"
                              width={11}
                              height={15}
                              className="w-[11px] h-[15px]"
                              alt=""
                            />
                            <span>
                            {translate('Fee', Language)}
                          </span>
                          </div>
                          <div className="border-[1px] w-32 border-[#f1f1f1] opacity-20" />
                          <p className="text-[20px] font-bold text-[#FFA35B] flex justify-center items-center gap-1 overflow-hidden">
                            {item.price}{' '}
                            <Image
                              src="/assets/packets/azn.png"
                              width={20}
                              height={20}
                              className="h-5"
                              alt=""
                            />
                          </p>
                          <Popup
                            trigger={
                              <p className="w-[100px] h-[30px] text-[8px] flex justify-center items-center font-medium text-white bg-[#AB31D6] rounded-full cursor-pointer">
                                {translate('Detailed_information', Language)}
                              </p>
                            }
                            modal
                            nested
                            contentStyle={{
                              padding: '0px',
                              borderRadius: '10px',
                              borderColor: 'white',
                              width: '90%',
                              height: 'auto',
                              overflow: 'auto',
                            }}
                          >
                            {(close) => (
                              <>
                                <Image
                                  src="/assets/popup/x.png"
                                  className="absolute right-2 top-2 w-[34px] h-[36px]"
                                  alt=""
                                  width={40}
                                  height={42}
                                  onClick={() => {
                                    close();
                                    setIsOpen(false);
                                  }}
                                />
                                <div className="flex justify-center items-center p-5 mt-5 break-keep">
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: item.description,
                                    }}
                                  ></span>
                                </div>
                              </>
                            )}
                          </Popup>
                        </div>
                        <div className="flex justify-center">
                          <div
                            className={`${
                              item.most_wanted === true ? 'flag-home' : 'hidden'
                            }   mt-0 text-[8px] text-center font-medium justify-center`}
                          >
                            {translate('Preferred', Language)}
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </>
          )}
          {selectedItem === 'biznes' && (
            <>
              <div className="swiper">
                <Swiper
                  slidesPerView={2}
                  centeredSlides={true}
                  spaceBetween={170}
                  pagination={{
                    clickable: true,
                  }}
                  modules={[Pagination]}
                  className="mySwiper"
                >
                  {type2Tariffs?.map((item) => (
                    <SwiperSlide key={item.id}>
                      {' '}
                      <div className="h-[410px] w-[210px] flex flex-col justify-center items-center p-0 op">
                        <div
                          className={`w-[200px] h-[350px] max-sm:w-[195px] max-sm:h-[332px] rounded-t-[100px]  rounded-b-[20px] bg-gradient-to-r from-[#653E98] to-[#3E2164] flex flex-col justify-start items-center ${
                            item.ip_tv === true ? 'gap-2' : 'gap-3'
                          }   relative z-10   ${
                            item.most_wanted === true
                              ? 'outline-red'
                              : undefined
                          }`}
                        >
                          <div className="flex justify-center items-center w-[65px] h-[65px] bg-[#AB31D6] rounded-full mt-3">
                            <Image
                              src={item.icon}
                              width={20}
                              height={20}
                              alt=""
                            />
                          </div>
                          <p className="text-[20px] font-bold text-white">
                            {item.name}
                          </p>
                          <div className="border-[1px] w-32 border-[#f1f1f1] opacity-20" />
                          <p className="text-white text-[24px] font-bold">
                            {item.speed} Mb/s
                          </p>
                          <div
                            className={`${
                              item?.ip_tv === true ? 'block' : 'hidden'
                            } bg-[#FFA35B] w-full h-[28px]  flex  justify-center items-center gap-2  `}
                          >
                            <Image
                              src="/assets/packets/tv2.png"
                              width={11}
                              height={15}
                              className="w-[11px] h-[15px]"
                              alt=""
                            />
                            <p className="text-[10px] font-bold text-[#5B2D90]">
                              {translate('IP_TV', Language)}
                            </p>
                          </div>
                          <div className="text-white text-[10px] font-bold flex gap-1">
                            <Image
                              src="/assets/packets/pq2.png"
                              width={11}
                              height={15}
                              className="w-[11px] h-[15px]"
                              alt=""
                            />
                            <span>
                            {translate('Fee', Language)}
                          </span>
                          </div>
                          <div className="border-[1px] w-32 border-[#f1f1f1] opacity-20" />
                          <p className="text-[20px] font-bold text-[#FFA35B] flex justify-center items-center gap-1 overflow-hidden">
                            {item.price}{' '}
                            <Image
                              src="/assets/packets/azn.png"
                              width={20}
                              height={20}
                              className="h-5"
                              alt=""
                            />
                          </p>
                          <Popup
                            trigger={
                              <p className="w-[100px] h-[30px] text-[8px] flex justify-center items-center font-medium text-white bg-[#AB31D6] rounded-full cursor-pointer">
                                {translate('Detailed_information', Language)}
                              </p>
                            }
                            modal
                            nested
                            contentStyle={{
                              padding: '0px',
                              borderRadius: '20px',
                              borderColor: 'white',
                              width: '90%',
                              height: 'auto',
                              overflow: 'hidden',
                            }}
                          >
                            {(close) => (
                              <>
                                <Image
                                  src="/assets/popup/x.png"
                                  className="absolute right-2 top-2 w-[34px] h-[36px]"
                                  alt=""
                                  width={40}
                                  height={42}
                                  onClick={() => {
                                    close();
                                    setIsOpen(false);
                                  }}
                                />
                                <div className="flex justify-center items-center p-5 mt-5 break-keep">
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: item.description,
                                    }}
                                  ></span>
                                </div>
                              </>
                            )}
                          </Popup>
                        </div>
                        <div className="flex justify-center">
                          <div
                            className={`${
                              item.most_wanted === true ? 'flag-home' : 'hidden'
                            }   mt-0 text-[8px] text-center font-medium justify-center`}
                          >
                            {translate('Preferred', Language)}
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </>
          )}
          <div className="relative   text-[20px] max-xl:text-[16px] font-medium text-white flex gap-5 justify-center items-center ">
            <button className="border max-xxl:mt-0 mt-[-60px]  w-[244px] h-[60px] max-xl:w-[196px] max-xl:h-[36px]  border-white  flex justify-center items-center max-sm:p-0 rounded-3xl ">
              {home_page_other_tariffs_button_link && home_page_other_tariffs_button_link[0] !== null ? (
                <a
                  href={
                    home_page_other_tariffs_button_link
                      ? `${home_page_other_tariffs_button_link}`
                      : '#/'
                  }
                >
                  {translate('Other_tariffs', Language)}
                </a>
              ) : (
                <p> {translate('Other_tariffs', Language)}</p>
              )}
            </button>
          </div>{' '}
          <Image
            src="/assets/packets/wifi2.png"
            width={300}
            height={300}
            className="wifi_2 max-md:w-[130px] max-xl:w-[200px] w-[300px]"
            alt=""
          />
        </div>
      </div>{' '}
      <div
        className={`  home-wrapper-5 w-full   mx-auto pt-20 pb-20 relative  `}
      >
        <div className="container max-w-7xl mx-auto flex flex-col justify-center items-center">
          <h3 className="text-[40px] text-[#5B2D90] font-bold text-center max-md:text-[20px]  max-xl:text-[30px]">
            {translate('Customer_reviews', Language)}
          </h3>
          <div className="w-full">
            <div className="swiper">
              <Swiper
                slidesPerView={1}
                spaceBetween={0}
                navigation={{
                  prevEl: '.custom-prev',
                  nextEl: '.custom-next',
                }}
                className="mySwiper3"
              >
                {ReviewData?.data?.map((item) => {
                  return (
                    <SwiperSlide key={item.id}>
                      {' '}
                      <div className="flex overflow-hidden" key={item.id}>
                        <div className="w-[600px] h-[400px] border flex flex-col justify-center p-10 gap-8 rounded-2xl mt-10 border-[#5B2D90]">
                          <p className="text-[14px] text-[#5F7285] leading-5 font-medium italic">
                            {item.comment}
                          </p>
                          <div className="flex items-center gap-1">
                            <Image
                              src={item.reviewer_image}
                              width={54}
                              height={54}
                              className=" rounded-full w-[54px] h-[54px]"
                              alt=""
                            />
                            <h4 className="text-[14px] text-[#1B263D] font-semibold">
                              {item.reviewer_name}
                            </h4>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })}
                <SwiperNavButtons />
              </Swiper>
            </div>
          </div>
          <div className="w-full">
            <div className="swiper">
              <Swiper
                slidesPerView={'auto'}
                centeredSlides={true}
                spaceBetween={1}
                pagination={{
                  clickable: true,
                }}
                modules={[Pagination]}
                className="mySwiper2"
              >
                <>
                  {ReviewData?.data?.map((item) => {
                    return (
                      <SwiperSlide key={item.id}>
                        {' '}
                        <div className="flex overflow-hidden" key={item.id}>
                          <div className="w-[600px] h-[400px] mx-2 border flex flex-col justify-center p-10 gap-8 rounded-2xl mt-10 border-[#C4C4C4]">
                            <p className="text-[14px] text-[#5F7285] leading-5 font-medium italic">
                              {item.comment}
                            </p>
                            <div className="flex items-center gap-1">
                              <Image
                                src={item.reviewer_image}
                                width={54}
                                height={54}
                                alt=""
                              />
                              <h4 className="text-[14px] text-[#1B263D] font-semibold">
                                {item.reviewer_name}
                              </h4>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    );
                  })}
                </>
              </Swiper>
            </div>
          </div>
        </div>
      </div>
      <div className="home-wrapper-7 w-full mt-10 pb-20 mx-auto relative max-xxl:flex max-xxl:flex-col max-xxl:justify-center max-xxl:items-center  bg-[#F7F6FB]  ">
        <h3 className="text-[40px] max-md:text-[20px] max-xxl:text-[30px] text-[#5B2D90] relative pt-5 my-10 max-sm:mt-[20px] max-sm:mb-[0px] font-bold text-center">
          {translate('News', Language)}
        </h3>
        <div className="container max-w-[966px] mx-auto flex flex-col gap-10 justify-center items-center">
          <div className="w-full">
            <div className="swiper">
              <Swiper
                  slidesPerView={3}
                  spaceBetween={20}
                  navigation={{
                    prevEl: '.custom-prev',
                    nextEl: '.custom-next',
                  }}
                  className="mySwiper3"
                >
                  {NewsData?.data?.map((item) => {
                    return (
                      <SwiperSlide key={item.id}>
                        <div
                          className="flex flex-col justify-center"
                          key={item.id}
                        >
                          <div key={item.id} onClick={() => handleClick(item.slug)}>
                            <div className="max-sm:flex max-sm:flex-col max-sm:justify-center">
                              {' '}
                              <Image
                                src={item.image}
                                width={500}
                                height={300}
                                className="w-[280px] h-[168px] rounded-3xl"
                                alt=""
                              />
                              <h2 className="bg-[#5B2D90] text-[12px] rounded-xl w-[136px] text-center py-2   mb-2 text-white mt-5">
                                {item.published_at}
                              </h2>
                              <h3 className="text-[#212B36] text-[24px] font-bold text-left">
                                {item.title}
                              </h3>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    );
                  })}
                <SwiperNavButtons />
              </Swiper>
            </div>
          </div>
          <div className="w-full">
            <div className="swiper">
              <Swiper
                slidesPerView={'auto'}
                centeredSlides={true}
                spaceBetween={1}
                pagination={{
                  clickable: true,
                }}
                modules={[Pagination]}
                className="mySwiper2"
              >
                <>
                  {NewsData?.data?.map((item) => {
                    return (
                      <SwiperSlide key={item.id}>
                        <div
                          className="flex flex-col justify-center mx-10 mb-10"
                          key={item.id}
                        >
                          <div key={item.id} onClick={() => handleClick(item.slug)}>
                            <div className="max-sm:flex max-sm:flex-col max-sm:justify-center">
                              {' '}
                              <Image
                                src={item.image}
                                width={500}
                                height={300}
                                className="w-[280px] h-[168px] rounded-3xl"
                                alt=""
                              />
                              <h2 className="bg-[#5B2D90] text-[12px] rounded-xl w-[136px] text-center py-2   mb-2 text-white mt-5">
                                {item.published_at}
                              </h2>
                              <h3 className="text-[#212B36] text-[24px] font-bold text-left">
                                {item.title}
                              </h3>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    );
                  })}
                </>
              </Swiper>
            </div>
          </div>
        </div>
      </div>
      <div className="home-wrapper-8 w-full  pb-20 mx-auto relative  py-20  ">
        <div className=" max-w-5xl mx-auto flex justify-center">
          <div className="relative w-[752px] h-[337px] max-sm:w-[280px] max-sm:h-[280px] max-md:w-[450px] max-md:h-[300px] bg-[#5B2D90] p-10 max-md:px-2 max-md:py-5 rounded-3xl flex flex-col gap-10 max-sm:gap-3 max-md:gap-10  justify-center items-center">
            <Image
              src="/assets/packets/wifi2.png"
              width={300}
              height={300}
              className="wifi_3 max-md:w-[130px] max-xl:w-[200px] w-[300px]"
              alt=""
            />
            <h2 className="text-white text-[24px] font-bold max-md:text-[20px] max-md:text-center">
              {career_title}
            </h2>
            <p className="text-white text-[16px] w-4/5 font-normal text-center max-md:text-[12px] max-sm:w-full overflow-hidden">
              {career_description}
            </p>
            <Link href={`${currentLanguage}/career`} onClick={handleScrollUp}>
              <button className="w-[172px] h-[52px] max-md:w-[190px] max-md:h-[41px] bg-white text-[#5B2D90] rounded-full text-[16px] font-medium">
                {translate('Apply', Language)}
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default home;