import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import { NewsDataProvider } from './NewsData';
import { useTranslation } from './TranslationContext';

const Service = ({ ServiceCategoryData }) => {
  const { translate, Language, currentLanguage } = useTranslation();

  return (
    <>
      <NewsDataProvider ServiceCategoryData={ServiceCategoryData} />
      <div className="home-wrapper-1 container max-w-5xl max-sm:hidden py-10 mx-auto relative overflow-hidden max-xl:hidden">
        <div className="grid grid-cols-3 justify-items-center">
          {ServiceCategoryData?.data?.map((item) => (
            <div key={item.id} className="flex flex-col items-center">
              <div className="w-[102px] h-[102px] rounded-3xl flex items-center mx-auto">
                <Image
                  src={item.icon}
                  width={102} // Ensure this matches your styling
                  height={102} // Ensure this matches your styling
                  className="w-[102px] h-[102px] mx-auto"
                  alt={`${item.name} icon`} // Provide descriptive alt text
                />
              </div>
              <div className="text-center">
                <h3 className="font-medium text-[28px] py-4 tracking-[0.5px]">
                  {item.name}
                </h3>
                <ul className="flex flex-col justify-center items-center gap-2 text-[#909090] font-normal">
                  {item.services?.map((service) => (
                    <li key={service.id}>
                      <Link href={`/${currentLanguage}/services/${service.id}`}>
                        {service.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Service;
